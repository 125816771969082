import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./app.scss";
import './font-awesome.min.css';

import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import LinkItem        from "./components/LinkItem/LinkItem";
import GeoLocation     from "./components/GeoLocation";

// import { PhoneItem }       from "./components/PhoneItem/PhoneItem";
import { ConsultationButton } from "./components/ConsultationButton";
import { FormDialog }      from "./components/FormDialog/FormDialog";
import { PromotionDialog } from "./components/PromotionDialog/PromotionDialog";
import { OptionsDialog }   from "./components/PromotionDialog/OptionsDialog";


import { WindowsOurListing } from "./components/WindowsOurListing";
import Modal from "./components/Modal";


// import { ReferralProgram } from "./components/ReferralProgram/";
import { baseUrl } from "./const";

import Logo from "./assets/images/Landing/svg/logo.svg";
import Flooring from "./assets/images/Landing/svg/flooring.svg";
import Bathroom from "./assets/images/Landing/svg/bathroom.svg";
import Kitchen from "./assets/images/Landing/svg/kitchen.svg";
import Basement from "./assets/images/Landing/svg/basement.svg";
import Painting from "./assets/images/Landing/svg/painting.svg";
// import Drywall from "./assets/images/Landing/svg/drywall.svg";
import Windows from "./assets/images/Landing/svg/windows-doors.svg";
import Roofs from "./assets/images/Landing/svg/new-roofing.svg";
import Coverings from "./assets/images/Landing/svg/coverings.svg";
import PaintingFlooring from "./assets/images/Landing/svg/Union.svg";

// import Plumbing from "./assets/images/Landing/svg/plumbing.svg";
// import HVAC from "./assets/images/Landing/svg/snowflake.svg";
// import OtherProjects from "./assets/images/Landing/other_projects.png";
import ClosetOrganizers from "./assets/images/Landing/svg/closetorg.svg";
import promotionagm from "./assets/images/multydeal2.png";
import Next from "./assets/images/Landing/svg/next.svg";
import Additional from "./assets/images/Landing/svg/additional.svg";
import Realtors from "./assets/images/Landing/svg/realtors.svg";

const handler = {};
const SERVICES = [
  {
    image: Basement,
    value: "Basement",
    title: "Basement Renovations",
    link: "https://agmbasements.com/",
    availableFor: ["gta"],
    unavailableText: "Service is not available in your city",
  },
  {
    image: Bathroom,
    value: "Bathroom",
    title: "Bathroom Renovations",
    link: "https://bathroomrenovations.com/",
  },
  {
    image: Kitchen,
    value: "Kitchen",
    title: "Kitchen Renovations",
    link: "https://agmkitchens.com/",
  },

  // {
  //   image: Roofs,
  //   value: "Roofing",
  //   title: "Roofing",
  //   link: "https://agmroofs.com/",
  // },
  // {
  //   image: PaintingFlooring,
  //   value: "Flooring & Painting",
  //   title: "Flooring &_Painting",
  //   visible: 'desktop',
  //   availableFor: ["gta"],
  //   optionServices: [
  //     {
  //       image: Flooring,
  //       value: "Flooring",
  //       title: "Flooring",
  //       link: "https://agmflooring.com/",
  //     },
  //     {
  //       image: Painting,
  //       value: "Painting",
  //       title: "Painting",
  //       link: "https://agmpainting.com/",
  //     }
  //   ],
  //   link: "",
  // },
  // {
  //   image: Flooring,
  //   value: "Flooring",
  //   title: "Flooring &_Stairs",
  //   visible: 'mobile',
  //   availableFor: ["non_gta"],
  //   link:  "https://agmflooring.com/",
  // },
  // {
  //   image: Painting,
  //   value: "Painting",
  //   title: "Painting",
  //   visible: 'mobile',
  //   availableFor: ["non_gta"],
  //   link:  "https://agmpainting.com/",
  // },
  // {
  //   image: Next,
  //   value: "Other Services",
  //   title: "More Services",
  //   // visible: 'desktop',
  //   availableFor: ["gta"],
  //   optionServices: [
  //     {
  //       image: ClosetOrganizers,
  //       value: "Closet Organizers",
  //       title: "Custom Closets",
  //       availableFor: ["gta"],
  //       link: "https://agmkitchens.com/",
  //       option: 'closetorg',
  //     },
  //     {
  //       image: Windows,
  //       value: "Windows",
  //       title: "Windows &_Doors",
  //       availableFor: ["gta"],
  //       link: "https://agmwindows-doors.com/",
  //     },
  //     {
  //       image: Coverings,
  //       value: "Window Coverings",
  //       title: "Blinds",
  //       availableFor: ["gta"],
  //       link: "https://agmkitchens.com/blinds/",
  //       option: 'blinds',
  //     },
  //     {
  //       image: Basement,
  //       value: "Foundation Cracks Repair",
  //       title: "Wet Basement",
  //       availableFor: ["gta"],
  //       link: "https://agmcrackrepair.com/",
  //     },
  //     {
  //       image: Additional,
  //       value: "SunRooms & Home Additions",
  //       title: "Sunrooms &_Home*Additions",
  //       availableFor: ["gta"],
  //       link: "https://agmhomeadditions.com/",
  //     },
  //     {
  //       image: Realtors,
  //       value: "Sell_Your_Home",
  //       title: "Sell Your*Home?",
  //       availableFor: ["gta"],
  //       link: "https://agmrealtor.com/",
  //     }
  //   ],
  //   link: "",
  // },
  




  // {
  //   image: ClosetOrganizers,
  //   value: "Closet Organizers",
  //   title: "Custom Closets",
  //   visible: 'mobile',
  //   availableFor: ["gta"],
  //   link: "https://agmkitchens.com/",
  //   option: 'closetorg',
  // },
  // {
  //   image: Windows,
  //   value: "Windows",
  //   title: "Windows &_Doors",
  //   visible: 'mobile',
  //   availableFor: ["gta"],
  //   link: "https://agmwindows-doors.com/",
  // },
  // {
  //   image: Coverings,
  //   value: "Window Coverings",
  //   title: "Blinds",
  //   visible: 'mobile',
  //   availableFor: ["gta"],
  //   link: "https://agmkitchens.com/",
  //   option: 'blinds',
  // },
  // {
  //   image: Basement,
  //   value: "Foundation Cracks Repair",
  //   title: "Foundation Crack_Repair",
  //   visible: 'mobile',
  //   availableFor: ["gta"],
  //   link: "https://agmcrackrepair.com/",
  // },
  // {
  //   image: Basement,
  //   value: "Free In-Home Inspection",
  //   title: "Home Inspection",
  //   visible: 'mobile',
  //   availableFor: ["gta"],
  //   // link: "https://agmcrackrepair.com/",
  // },



  // {
  //   image: ClosetOrganizers,
  //   value: "Custom Closets",
  //   title: "Custom Closets",
  //   // availableFor: ["gta"],
  //   link: "https://agmkitchens.com/",
  //   option: 'closetorg',
  // },

  // {
  //   image: Drywall,
  //   value: "Drywall Work",
  //   title: "Drywall Work",
  //   availableFor: ["gta"],
  //   link: "",
  // },

];

// const fetchCurrentRegion = async (isRepeat = false) => {
//   try {
//     const apiKey = "7c4c3d1f99a44ba69674868663bd2437";
//     const response = await axios(
//       `https://api.geoapify.com/v1/ipinfo?apiKey=${apiKey}`
//     );
//     return response.data.state.name;
//   } catch (err) {
//     return "Ontario";
//   }
// };

const App = () => {

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenDialogPromo, setIsOpenDialogPromo] = useState(false);
  const [isOpenDialogOption, setIsOpenDialogOption] = useState(false);
  const [updState, setUpdState] = useState(false);
  // const [socialLinks, setSocialLinks] = useState(null);
  const socialLinks = null;

  

  const [optionServices, setOptionServices] = useState(null);
  const [choosenCompany, setChoosenCompany] = useState(null);
  const [region, setRegion] = useState("");
  const [isShowPhoneNumber, setIsShowPhoneNumber] = useState(false);
  const [geoData, setGeoData] = useState(null);
  const [userLocation, setUserLocation] = useState("");
  // const [offset, setOffset] = useState(0);
  const servicesRef = useRef(null);
  const services = useMemo(() => (
      SERVICES.filter(
        (service, ind, arr) => {
          if (geoData && service.availableFor) {

            if (service.visible === 'mobile' && window.innerWidth <= 660) {
              return service;
            }

            if (geoData.currentLocation) {
              if (!service.availableFor.includes(geoData.currentLocation.toLowerCase())) return null;
            } else if (geoData.location.some((loc) => !service.availableFor.includes(loc.province.toLowerCase()))) {
              if (service.availableFor[0] === 'non_gta' && !geoData.location.some((loc) => loc.country === "Canada")) {
                delete service.visible;
                return service;
              }
              return null;
            }
          }
     
        return service;
      }
  )), [geoData]);
  
  if (window.innerWidth > 660) {
    while(services.some(item => item.visible === 'mobile')) {
      let mobInd = null; 
      services.find((item, index) => { if (item.visible === 'mobile') mobInd = index; });
      services.splice(mobInd, 1);
    }
  }

  const handleOptionServices = (serviceData) => {
    setOptionServices(serviceData);
    setIsOpenDialogOption(true);
  };
  const handleRedirect = (serviceData) => {
    setChoosenCompany(serviceData);
    setIsOpenDialog(true);
  };
  const handlePhoneButtonClick = () => {
    setIsShowPhoneNumber(true);
    setIsOpenDialog(true);
  };
  const updateUserLocation = (data) => {
    if (data && data.postal) {
      setUserLocation(
        `?loc=${data.location[0]}_${data.location[1]}&reg=${data.region}&postal=${data.postal}`
      );
    }

  };
  const makeServiceActiveonMobileScroll = (e) => {
    if (window.innerWidth > 660) {
      if (servicesRef && servicesRef.current)
        servicesRef.current.childNodes.forEach((list) => {
          list.childNodes.forEach((item) => {
            item.children[0].children[0].classList.remove("active");
          });
        });

        if (services.some(item => item.visible === 'mobile')) {
          while(services.some(item => item.visible === 'mobile')) {
            let mobInd = null; 
            services.find((item, index) => { if (item.visible === 'mobile') mobInd = index; });
            services.splice(mobInd, 1);
          }
          setUpdState(!updState);
        }
      return;
    } 


    let closestNode = null;
    // let closestDist = null;

    if (servicesRef && servicesRef.current) {
      servicesRef.current.childNodes.forEach((list, indexlist, arr) => {
        list.childNodes.forEach((item, index) => {
          const itemPosY = item.getBoundingClientRect().top;
          item.children[0].children[0].classList.remove("active");

          if (itemPosY > 60 && itemPosY < 300) {
            closestNode = item.children[0].children[0];
          }

        });
      });

      if (closestNode) {
        closestNode.classList.add("active");
      }
    }
  };
  useEffect(() => {
    const region = sessionStorage.getItem("region")
      ? sessionStorage.getItem("region")
      : "gta";
    setRegion(region);
    
    makeServiceActiveonMobileScroll();

    window.removeEventListener("scroll", makeServiceActiveonMobileScroll);
    window.addEventListener("scroll", makeServiceActiveonMobileScroll, {
      passive: true,
    });
    window.addEventListener("resize", makeServiceActiveonMobileScroll);
    return () => {
      window.removeEventListener("scroll", makeServiceActiveonMobileScroll);
      window.removeEventListener("resize", makeServiceActiveonMobileScroll);
    };
  }, []);
  
  const renderDynamicServices = (s, e, col) => {
    return (
      <ul key={e} className="link_list" style={{ gridTemplateColumns: `repeat(${col || e-s}, 1fr)`, justifyItems: 'center' }}>
        {services.slice(s, e).map((itemService, index) => (
          <LinkItem
            key={index}
            {...itemService}
            onClick={handleRedirect}
            opeFormOptions={handleOptionServices}
            geoData={region}
            userLocation={userLocation}
          />
        ))}
      </ul>
    );
  };
  const renderServicesRaws = () => {
    
      let servicesRows = null;

      if (services.length > 12) {
        servicesRows = [
          renderDynamicServices(0, 4),
          renderDynamicServices(4, 8),
          renderDynamicServices(8, 16, services.length - 8)
        ];
      } else if (services.length > 8) {
        servicesRows = [
          renderDynamicServices(0, 4),
          renderDynamicServices(4, 8),
          renderDynamicServices(8, 12, services.length - 8)
        ];
      } else if (services.length > 6) {
        servicesRows = [
          renderDynamicServices(0, 4),
          renderDynamicServices(4, 8, services.length - 4),
          // renderDynamicServices(6, 9, services.length - 6)
        ];
      } else if (services.length > 4) {
        servicesRows = [
          renderDynamicServices(0, 3),
          renderDynamicServices(3, 6, services.length - 3)
        ];
      } else if (services.length > 2) {
        servicesRows = [
          renderDynamicServices(0, 3),
          renderDynamicServices(3, 6, services.length - 3)
        ];
        // servicesRows = [
        //   renderDynamicServices(0, 2),
        //   renderDynamicServices(2, 4, services.length - 2)
        // ];
      } else {
        servicesRows = [
          renderDynamicServices(0, 2, services.length)
        ];
      }

      return servicesRows;
  };

  return (
    <main className="main">

      <Helmet>
        <meta name="robots" content="index"/>
        <meta name="description"
            content="We transform your dreams in reality. AGM Renovations experts provide quality basement/bathroom finishing services throughout the GTA and the surrounding area. We offer 100% satisfaction guarantee on our workmanship and offer competitive prices with exceptional quality."
        />
        <meta name="keywords"
            content="basement renovations toronto, AGM basements, basement renovations toronto costs, finishing a basement cost estimator, finished basements, finished basement costs, basement finishing financing, AMG design, high qualty basements toronto, basement renovations vaughan, basement renovations in GTA, basement finish, steps to finishing a basement,"
        />
        <title>AGM Renovations - Basement and Bathroom Professionals</title>
      </Helmet>

      {/* <GeoLocation
        data={geoData}
        setGeoData={setGeoData}
        updateUserLocation={updateUserLocation}
        visible={true}
      /> */}

      <div className="bg_container">
        <div className="gradient"></div>
        <div className="left"></div>
        <div className="right"></div>
      </div>

      <header className="header">
        <a href={baseUrl} className="logo">
          <img src={Logo} alt="logo" />
        </a>
        <img className="mobileLogo" src={Logo} alt="logo" />
        {/* <div className="promotinal" 
          onClick={()=>{ 
            // if (!isOpenDialogPromo) setIsOpenDialogPromo(true); 
          }}>
          <img src={promotionagm}/>
        </div> */}
        {/* <PhoneItem phone={geoData && geoData.phone} /> */}
        {/* <ConsultationButton handeFormOpen={() => setIsOpenDialog(true)} /> */}
        <h1 className="content_title inheader">AGM Renovations is permanently closed</h1>
      </header>

      <section className="content">
        <h1 className="content_title incontent">AGM Renovations is permanently closed</h1>
        {/* <div
          ref={servicesRef}
          className="link_list_wrap"
          onScroll={makeServiceActiveonMobileScroll}
        >
          {renderServicesRaws()}
        </div> */}
      </section>

      {/* <Button className="phone_icon" onClick={handlePhoneButtonClick}></Button>

      <WindowsOurListing
        title=""
        subtitle=""
        noTitle={true}
          hideButton={true}
          handler={handler}
      >
        <Modal handler={handler} />
      </WindowsOurListing>

        <footer>
          <div className="footer-bottom">
            <div className="socials">
       
            <div className="floating-call-btn">
              <a href={"tel:" + (geoData ? geoData.phone : "")}>{geoData ? geoData.phone : ""}</a>
            </div>
              <div className="socialsMob" >
                <a
                  href={socialLinks ? socialLinks.find( item => item.type === 'facebook').url : "https://www.facebook.com/AGMRenovationsCompany"}
                  className="soc-btn soc-btn-f"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Facebook
                </a>
                <a
                  href={socialLinks ? socialLinks.find( item => item.type === 'twitter').url : "https://twitter.com/AGMRenovations"}
                  className="soc-btn soc-btn-tw"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Twitter
                </a>
                <a
                  href={socialLinks ? socialLinks.find( item => item.type === 'instagram').url : "https://www.instagram.com/AGM.Renovations"}
                  className="soc-btn soc-btn-ins"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Instagram
                </a>
                <a
                  href={socialLinks ? socialLinks.find( item => item.type === 'youtube').url : "https://www.youtube.com/channel/UCjahxbw8bMUK73g0OJaYm4g"}
                  className="soc-btn soc-btn-yt"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  YouTube
                </a>
                <a
                  href={socialLinks ? socialLinks.find( item => item.type === 'linkedin').url : "https://www.linkedin.com/company/agm-basements-renovation-finishing/"}
                  className="soc-btn soc-btn-in"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  LinkedIn
                </a>
                </div>

            </div>
          </div>
        </footer>

      {isOpenDialog && (
        <FormDialog
          isOpen={isOpenDialog}
          setIsOpen={setIsOpenDialog}
          service={choosenCompany}
          setCompany={setChoosenCompany}
          phone={geoData && geoData.phone}
          services={services}
          SERVICES_ALL={SERVICES}
          isShowPhoneNumber={isShowPhoneNumber}
          setIsShowPhoneNumber={setIsShowPhoneNumber}
        />
      )}

      {isOpenDialogPromo && 
        <PromotionDialog
          isOpen={isOpenDialogPromo}
          setIsOpen={setIsOpenDialogPromo}
          phone={geoData && geoData.phone}
          isShowPhoneNumber={isShowPhoneNumber}
          setIsShowPhoneNumber={setIsShowPhoneNumber}

          services={services}
          handleRedirect={handleRedirect}
          handleOptionServices={handleOptionServices}
          region={region}
          userLocation={userLocation}
        />
      }

      <Link to="/reviews" style={{ visibility: 'hidden', position: 'absolute' }}/>
      <Link to="/faqs" style={{ visibility: 'hidden', position: 'absolute' }}/>
      
      <a style={{ visibility: 'hidden', position: 'absolute' }} href="https://agmrenovationsreviews.com" alt="AGM reviews" target="_blank"></a>
      <a style={{ visibility: 'hidden', position: 'absolute' }} href="https://agmrenovationsreviews.ca" alt="AGM reviews" target="_blank"></a>
          

      {isOpenDialogOption && (
        <OptionsDialog
          isOpen={isOpenDialogOption}
          setIsOpen={setIsOpenDialogOption}
        >
          <section className="content">
            <div
              ref={servicesRef}
              className="link_list_wrap"
              onScroll={makeServiceActiveonMobileScroll}
            >
              <ul className="link_list" style={{ 
                  marginBottom: '24px', gridTemplateColumns: `repeat(${optionServices.length}, 1fr)`, 
                  justifyItems: 'center', transform: 'scale(0.75)',
                }}>
                {optionServices.map((itemService, index) => (
                    <LinkItem
                      key={index}
                      {...itemService}
                      onClick={handleRedirect}
                      opeFormOptions={handleOptionServices}
                      geoData={region}
                      className={(itemService.link === 'https://agmhomeadditions.com/' || itemService.link === 'https://agmrealtor.com/') ? 'scaled' : ''}
                      userLocation={userLocation}
                    />
                ))}
              </ul>
            </div>
          </section>
        </OptionsDialog>
      )} */}
      
    </main>
  );
};

export default App;
